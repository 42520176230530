<template>
  <Modal
    :is-open="isOpen"
    :content-overflow="true"
    :hide-action-buttons="true"
    :title="getTitle"
    :is-loading="showLoading"
    :persistent-on-off="true"
    @close="handleClose"
  >
    <div class="grid gap-y-8">
      <div class="flex flex-col space-y-4">
        <div v-if="!isAddWallet">
          <div class="mb-4">
            <Body
              v-if="!verifyActive"
              size="sm"
              class="mb-2 lg:mb-4"
            >
              {{ getSubTitle }}
            </Body>

            <Body
              v-else
              class="mb-4"
            >
              We just sent an email to <span class="text-black font-medium">{{ email }}</span> with a code to verify your identity. Please enter the code to access your account.
            </Body>

            <div
              v-if="!verifyActive"
              class=" w-full
                flex
                flex-col
                items-center
                relative"
            >
              <InputField
                id="email"
                v-model="email"
                class="py-3 lg:p-4 pr-[55px] lg:pr-[75px] lg:pl-6"
                name="email"
                type="email"
                placeholder="Enter your email"
                label="Use your email"
                :disabled="!isIdle"
                error-message="Please provide a valid email address"
                :show-validation="showEmailValidation"
                @valid="(valid: boolean) => isValidEmail = valid && email !== ''"
                @keydown="handleKeyDown"
              />

              <div class="absolute bottom-0 right-2 lg:right-2.5 -translate-y-[6px] lg:-translate-y-[8px]">
                <GlobalButton
                  priority="primary-light"
                  class="group w-10 h-10 lg:w-12 lg:h-12 hover:translate-y-[0px]"
                  :disabled="disableEmailSubmit"
                  size="sm"
                  @click.prevent="handleEmailSubmit"
                >
                  <span>
                    <Icon
                      icon="ArrowRightIcon"
                      class=" -translate-y-[2px] lg:-translate-y-[0px]  duration-300 transition-transform"
                      :class=" !disableEmailSubmit && 'group-hover:translate-x-[3px] group-active:translate-x-[0px]'"
                    />
                  </span>
                </GlobalButton>
              </div>
            </div>

            <div
              v-if="switchToLoginFlow"
              class="mb-4"
            >
              An account with that email already exists. <a
                class="text-black font-medium underline cursor-pointer"
                @click.prevent="()=>{handleSwitchToLogin()}"
                @click.stop
              >Log in</a>
            </div>

            <div
              v-if="switchToSignUpFlow"
              class="mb-4"
            >
              We could not find an account with that email address. <a
                class="text-black font-medium underline cursor-pointer"
                @click.prevent="()=>{handleSwitchToSignup()}"
                @click.stop
              >Create an account</a>
            </div>

            <div
              v-if="verifyActive"
              class="w-full flex flex-col items-center relative"
            >
              <InputField
                id="number"
                v-model="code"
                class="py-3 lg:p-4 lg:px-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                name="code"
                type="number"
                placeholder="eg 732457"
                maxlength="6"
                inputmode="numeric"
                autocomplete="one-time-code"
                required
                label="Enter your verification code"
                :disabled="isVerifyingCode"
                error-message="Please provide a valid code"
                @valid="(valid: boolean) => isValidCode = valid && code.length === 6"
                @keydown="handleKeyDown"
              />
            </div>

            <Body
              v-if="verifyActive"
              size="sm"
              class="mt-2"
            >
              Check your junk mail if you don't see an email from us in a couple minutes.
            </Body>
          </div>
        </div>

        <Body
          v-else
          size="sm"
          class="mb-2 lg:mb-4"
        >
          {{ getSubTitle }}
        </Body>

        <div
          v-if="!verifyActive && !isAddWallet"
          class="w-full flex justify-center"
        >
          <div class="flex h-px my-3 md:my-4 bg-slate-300 border-0 w-full mr-3" />

          <Body class="h-5 md:h-6">
            or
          </Body>

          <div class="flex h-px my-3 md:my-4 bg-slate-300 border-0 w-full ml-3" />
        </div>

        <div v-if="!verifyActive">
          <Title
            v-if="!isAddWallet"
            title-element="item-title"
            class="mb-1 md:mb-2"
          >
            Use your digital wallet
          </Title>

          <div class="rounded border border-brand-border">
            <button
              v-for="(connector, index) in walletConnectors"
              :key="connector.id"
              :disabled="disableConnectors"
              type="button"
              class="group w-full text-left overflow-hidden transform disabled:cursor-not-allowed disabled:-translate-y-0 disabled:bg-gray-100/50 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:ring-2 focus:ring-brand-primary focus:shadow-brand-primary/25 focus:shadow-outer transition-all"
              :class="[index === 0 ? 'rounded-t' : '', index === walletConnectors.length - 1 ? 'rounded-b' : '', '' ]"
              @click.prevent="walletSign({connector})"
            >
              <div
                v-if="connector.name === 'MetaMask'"
                :disabled="!isIdle"
                class="relative flex space-x-2 md:space-x-3 p-3 md:p-4 group-disabled:opacity-40 items-center"
                :class="[index !== walletConnectors.length - 1 ? 'border-b' : '']"
              >
                <div>
                  <MetaMaskLogo class="w-10 h-10 md:w-12 md:h-12" />
                </div>

                <div class="w-full">
                  <Title
                    :level="2"
                    title-element="small-title"
                  >
                    Metamask
                  </Title>
                </div>
              </div>

              <div
                v-else-if="connector.name === 'Coinbase Wallet'"
                :disabled="!isIdle"
                class="relative flex space-x-2 md:space-x-3 p-3 md:p-4 group-disabled:opacity-40 items-center"
                :class="[index !== walletConnectors.length - 1 ? 'border-b' : '']"
              >
                <div>
                  <CoinbaseWalletLogo class="w-10 h-10 md:w-12 md:h-12" />
                </div>

                <div class="w-full">
                  <Title
                    :level="2"
                    title-element="small-title"
                  >
                    Coinbase Wallet
                  </Title>
                </div>
              </div>

              <div
                v-else-if="connector.name === 'WalletConnect'"
                :disabled="!isIdle || disableConnectors"
                class="relative flex space-x-2 md:space-x-3 p-3 md:p-4 group-disabled:opacity-40 items-center"
                :class="[index !== walletConnectors.length - 1 ? 'border-b' : '']"
              >
                <div>
                  <WalletConnectLogo class="w-10 h-10 md:w-12 md:h-12" />
                </div>

                <div class="w-full">
                  <Title
                    :level="2"
                    title-element="small-title"
                  >
                    WalletConnect
                  </Title>
                </div>
              </div>

              <div
                v-else
                :disabled="!isIdle || disableConnectors"
                class="relative flex space-x-2 md:space-x-3 p-3 md:p-4 group-disabled:opacity-40 items-center"
                :class="[index !== walletConnectors.length - 1 ? 'border-b' : '']"
              >
                <img
                  :src="connector?.icon"
                  class="w-10 h-10 md:w-12 md:h-12 rounded-lg"
                  :class="connector.name === 'Brave Wallet' && 'shadow-lg'"
                >

                <div class="w-full">
                  <Title
                    :level="2"
                    title-element="small-title"
                  >
                    {{ connector?.name }}
                  </Title>
                </div>
              </div>
            </button>


            <div v-if="!isSignup && !isAddWallet && connectModalState.context.action !== 'create_community'">
              <button
                v-for="connector in emailConnectors"
                :key="connector.id"
                class="group w-full text-left overflow-hidden transform disabled:cursor-not-allowed disabled:-translate-y-0 disabled:bg-gray-100/50 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:ring-2 focus:ring-brand-primary focus:shadow-brand-primary/25 focus:shadow-outer transition-all"
                :disabled="disableConnectors"
                type="button"
                @click.prevent="walletSign({connector})"
              >
                <div class="relative border-t flex space-x-2 md:space-x-3 p-3 md:p-4 group-disabled:opacity-40 items-center">
                  <CrossMintLogo class="w-10 h-10 md:w-12 md:h-12" />

                  <div class="w-full">
                    <Title
                      :level="2"
                      title-element="small-title"
                    >
                      Crossmint
                    </Title>
                  </div>
                </div>
              </button>
            </div>

            <button
              v-if="!isAddWallet && isFlagEnabled('VITE_FEATURE_FARCASTER_ENABLED')"
              key="farcaster"
              :disabled="disableConnectors"
              type="button"
              class="group w-full text-left overflow-hidden transform disabled:cursor-not-allowed disabled:-translate-y-0 disabled:bg-gray-100/50 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:ring-2 focus:ring-brand-primary focus:shadow-brand-primary/25 focus:shadow-outer transition-all rounded-b"
              @click.prevent="handleFarcasterLogin"
            >
              <div
                :disabled="!isIdle"
                class="relative flex space-x-2 md:space-x-3 p-3 md:p-4 group-disabled:opacity-40 items-center border-t"
              >
                <div>
                  <FarcasterLogo class="w-10 h-10 md:w-12 md:h-12" />
                </div>

                <div class="w-full">
                  <Title
                    :level="2"
                    title-element="small-title"
                  >
                    Farcaster
                  </Title>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <Body size="sm">
        Having problems connecting?

        <button
          class="border-b-2 border-brand-copy-light hover:text-black  hover:border-white"
          @click.prevent="navigateToFAQ('accounts')"
        >
          Check out our FAQs
        </button>
      </Body>

      <div
        v-if="verifyActive"
        class="w-full flex space-x-4 justify-end"
      >
        <GlobalButton
          v-if="verifyActive"
          type="button"
          priority="secondary-light"
          @click.prevent="handleResendCode"
        >
          Resend
        </GlobalButton>

        <GlobalButton
          v-else
          type="button"
          priority="secondary-light"
          @click.prevent="handleClose"
        >
          Cancel
        </GlobalButton>

        <GlobalButton
          :disabled="isVerifyingCode || !isValidCode"
          :class="isVerifyingCode || isValidCode && 'hover:-translate-y-[3px]'"
          @click.prevent="handleSubmitCode"
        >
          Continue
        </GlobalButton>
      </div>

      <Modal
        :is-open="isFarcasterOpen"
        hide-action-buttons
        :content-overflow="false"
        contain-content
        persistent-on-off
        title="Log in with Farcaster"
        :subtitle="isMobile? `Open Warpcast to sign in` : `Scan with your phone's camera to continue`"
        @close="handleFarcasterClose"
      >
        <div class="flex justify-center">
          <FarcasterContent :farcaster-url="farcasterUrl || ''" />
        </div>
      </Modal>

      <Modal
        :is-open="isFarcasterNotFoundOpen"
        hide-action-buttons
        :content-overflow="false"
        contain-content
        persistent-on-off
        title="Farcaster account not found"
        @close.prevent="()=> isFarcasterNotFoundOpen = false"
      >
        <Body>
          You can <span
            class="text-black font-medium underline cursor-pointer"
            @click="handleNavigateToCreateAccount"
          >create a new account</span>  with your Farcaster account or log in to your existing account and attach Farcaster in your settings.
        </Body>
      </Modal>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref, watch, onUnmounted } from 'vue'
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'use-wagmi'
import { useRouter, useRoute } from 'vue-router'
import { isMobile, isAndroid } from '#utils'

import useFarcaster from '#composables/use-farcaster'
import useLogin from '#composables/use-login'
import { useLoginModal } from '#composables/use-login-modal'
import useSnackbar from '#composables/use-snackbar'
import { useAddWallet } from '#composables/use-add-wallet'
import useFeatureFlag from '#composables/use-feature-flag'

import { prioritizeConnectors } from '#utils/prioritizeConnectors'

import Body from '#components/typography/body'
import CrossMintLogo from '#components/logos/crossmint-logo.vue'
import MetaMaskLogo from '#components/logos/metamask-logo.vue'
import FarcasterLogo from '#components/logos/farcaster-logo.vue'
import Modal from '#components/modal'
import Title from '#components/typography/title'
import WalletConnectLogo from '#components/logos/walletconnect-logo.vue'
import CoinbaseWalletLogo from '#components/logos/coinbase-wallet-logo.vue'
import GlobalButton from '#components/global/global-button'
import InputField from '#components/input-field'
import Icon from '#components/icon.vue'
import FarcasterContent from '#components/farcaster/farcaster-content.vue'

const { state : connectModalState, send: sendConnectModal  } = useLoginModal()
const { addSnack } = useSnackbar()
const { state: authState, send: sendAuth, authService } = useLogin()
const { updateWalletInfo } = useAddWallet()
const { signMessage } = useSignMessage()
const { id: farcasterId, url: farcasterUrl } = useFarcaster()
const router = useRouter()
const route = useRoute()
const { isFlagEnabled } = useFeatureFlag()

const email = ref('')
const code = ref('')
const disableEmailSubmit = computed(() => !email.value && isIdle.value || ['loggedOut.signingIn.emailLogin.loading'].some(authState.value.matches))
const isValidCode = ref(false)
const isValidEmail = ref(false)
const resendingCode = ref(false)
const progressMessage = ref('')
const showLoading = ref(false)
const switchToLoginFlow = ref(false)
const switchToSignUpFlow = ref(false)
const showEmailValidation = ref(false)
const isFarcasterOpen = ref(false)
const isFarcasterLogin = ref(false)
const tempFarcasterAuthStarted = ref(false)
const isFarcasterNotFoundOpen = ref(false)

const isOpen = computed(() =>  ['open', 'connecting'].some(connectModalState.value.matches))
const isIdle = computed(()=> (['loggedOut.signingIn.idle','loggedOut.signingUp.idle','loggedOut.idle'].some(authState.value.matches)))
const isVerifyingCode = computed(()=> ['loggedOut.signingIn.emailLogin.verifying'].some(authState.value.matches))
const verifyActive = computed(()=> ['loggedOut.signingIn.emailLogin.pending', 'loggedOut.signingUp.emailSignUp.pending', 'loggedOut.signingIn.emailLogin.verifying', 'RESEND_CODE', 'loggedOut.signingIn.emailLogin.signIn'].some(authState.value.matches) || resendingCode.value === true)
const disableConnectors = computed(() => ['loggedOut.signingIn.emailLogin.loading', 'loggedOut.signingUp.emailSignUp.createAccount', 'loggedOut.signingIn.emailLogin.signIn','loggedOut.signingUp.emailSignUp.signIn', 'loggedOut.signingIn.walletLogin.signIn','loggedOut.signingIn.walletLogin.pending','loggedOut.signingIn.walletLogin.verifying'].some(authState.value.matches))

const isSignup = computed(() => connectModalState.value.context.action === 'signup')
const isLogin = computed(() => connectModalState.value.context.action === 'login')
const isAddWallet = computed(() => connectModalState.value.context.action === 'add-wallet')

const getTitle = computed(() => {
  if (verifyActive.value) {
    return 'Verify your email'
  } else if (isLogin.value) {
    return 'Welcome back'
  } else if (isAddWallet.value) {
    return 'Add a new wallet'
  } else {
    return 'Welcome to remx'
  }
})

const getSubTitle = computed(() =>{
  if (isLogin.value) {
    return 'Log in to remx with one of the following options:'
  } else if (isSignup.value) {
    return 'Create an account with one of the following options:'
  } else if (connectModalState.value.context.action === 'create_community') {
    return 'Use one of the following options to create your remx account.'
  } else if (isAddWallet.value) {
    return 'Connect your wallet with one of the following options:'
  } else {
    return 'Use one of the following options to login to your remx account.'
  }
})

const handleNavigateToCreateAccount = () => {
  isFarcasterNotFoundOpen.value = false
  const previousUrl = route.path as string
  router.push({ name: 'start-create-account', query: { page: previousUrl } })
  handleClose()
}

const subscriber = authService.subscribe(async (state) => {
  switchToSignUpFlow.value = false
  switchToLoginFlow.value = false

  if (['loggedOut.signingIn.idle'].some(state.matches)) {
    showLoading.value = false

    if (state.context.error == 'Error: An account with this email address was not found.') {
      showLoading.value = false

      handleSwitchToSignup()
      handleEmailSubmit()
    } else if (state.context.error == 'Error: An account with this wallet address was not found.') {
      showLoading.value = false

      if (isFarcasterLogin.value)
      {
        isFarcasterNotFoundOpen.value = true

        showLoading.value = false
        isFarcasterLogin.value = false
      }
      else
      {
        handleSwitchToSignup()
        walletSign({ connector: selectedConnector.value })
      }
    }
  } else if (['loggedOut.signingIn.walletLogin.pending','loggedOut.signingUp.walletSignUp.pending'].some(state.matches)) {
    if (isFarcasterLogin.value && !tempFarcasterAuthStarted.value) {
      const { id:farcasterId } = useFarcaster()
      tempFarcasterAuthStarted.value = true
      sendAuth({ type: 'VERIFY', data:  farcasterId.value })
    } else if (!tempFarcasterAuthStarted.value) {
      signMessage({ message: state.context.user.challengeParam.message }, {
        onError: (error) => {
          sendAuth({ type: 'CANCEL' })
        },
        onSuccess: (data) => {
          sendAuth({ type: 'VERIFY', data })
        },
      })
    }

    showLoading.value = true
  } else if (state.context.error == 'Error: Invalid verification code, a new code has been sent.') {
    showLoading.value = false

    addSnack({ message: 'Invalid verification code, a new code has been sent.', color: 'negative' })
  } else if (state.context.error && state.context.error.toString().includes('REMX_ACCOUNT_EMAIL_IN_USE')) {
    showLoading.value = false

    const nextEmail = state.context.error.toString().split(':')[2]
    email.value = nextEmail

    handleSwitchToLogin()
    handleEmailSubmit()
  } else if (state.context.error && state.context.error.toString().includes('REMX_ACCOUNT_ADDRESS_IN_USE')) {
    showLoading.value = false

    handleSwitchToLogin()
    walletSign({ connector: selectedConnector.value })
  } else if (['loggedOut.signingIn.emailLogin.pending'].some(state.matches)) {
    showLoading.value = false
  } else if (['loggedOut.signingUp.emailSignUp.success','loggedOut.signingUp.walletSignUp.success', 'loggedOut.signingUp.farcasterSignUp.success'].some(state.matches)) {
    sendAuth({ type: 'CONTINUE' })
  } else if (['loggedIn.idle'].some(authState.value.matches) && isOpen) {
    sendConnectModal('CLOSE')

    email.value = ''
    code.value = ''
    isValidCode.value = false
    isValidEmail.value = false
    resendingCode.value = false
    showLoading.value = false
    tempFarcasterAuthStarted.value = false
    isFarcasterLogin.value = false
    isFarcasterOpen.value = false
  } else {
    progressMessage.value = ''
  }
})

const handleFarcasterLogin = async () => {
  const { waitToSign } = useFarcaster()
  isFarcasterOpen.value = true
  isFarcasterLogin.value = true
  sendAuth('CANCEL')
  sendConnectModal({ type: 'SET_ACTION', data: 'login' })
  sendAuth('SIGN_IN')

  const success = await waitToSign()
  const { address: farcasterAccountId } = useFarcaster()
  if (!success) {
    addSnack({ message: `Error logging in with Farcaster`, color: 'negative' })
    isFarcasterOpen.value = false
  } else {
    sendAuth({ type: 'WALLET_LOGIN', data: { address: farcasterAccountId.value?.toString(), connectionType: 'Farcaster' } })
    isFarcasterOpen.value = false
  }
}

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    if (verifyActive.value) {
      handleSubmitCode()
    } else {
      handleEmailSubmit()
    }
  }
}

const handleEmailSubmit = () => {
  // if email validation is wrong, don't submit and show input error
  if (!isValidEmail.value) {
    showEmailValidation.value = true
    return
  }

  // continue here if validation is good
  showLoading.value = true
  if (isLogin.value) {
    sendAuth('SIGN_IN')
    sendAuth({ type: 'EMAIL_LOGIN', data: email })
  } else {
    sendAuth('SIGN_UP')
    sendAuth({ type: 'EMAIL_SIGN_UP', data: email })
  }
}

const handleSubmitCode = () => {
  showLoading.value = true

  sendAuth({ type: 'VERIFY', data: code.value })
}

const handleSwitchToLogin = () => {
  switchToLoginFlow.value = false

  sendAuth('CANCEL')
  sendConnectModal({ type: 'SET_ACTION', data: 'login' })
  sendAuth('SIGN_IN')
}

const handleSwitchToSignup = () => {
  switchToSignUpFlow.value = false

  sendAuth('CANCEL')
  sendConnectModal({ type: 'SET_ACTION', data: 'signup' })
  sendAuth('SIGN_UP')
}

const handleClose = () => {
  sendAuth('CANCEL')
  sendConnectModal('CLOSE')

  close()
}

const handleFarcasterClose = () => {
  isFarcasterOpen.value = false
}

const handleResendCode = () => {
  resendCode()
}

const resendCode = async () => {
  resendingCode.value = true

  try {
    sendAuth('RESEND_CODE')
    handleVerifyEmailSuccess()
  } catch (error) {
    console.error(error)

    handleVerifyEmailError()
  } finally {
    resendingCode.value = false
  }
}

const handleVerifyEmailSuccess = () => {
  addSnack({ message: 'We just sent an email to ' + email.value + ' with a code to verify your identity.', color: 'brand' })
}

const handleVerifyEmailError = () => {
  addSnack({ message: 'Oh shoot! There was a problem with your verification code. Please try again.', color: 'negative' })
}

const close = () => {
  sendConnectModal('CLOSE')
  sendAuth('CANCEL')

  email.value = ''
  code.value = ''
  isValidCode.value = false
  isValidEmail.value = false
  resendingCode.value = false
  showLoading.value = false
  tempFarcasterAuthStarted.value = false
  isFarcasterLogin.value = false
  isFarcasterOpen.value = false
  isFarcasterNotFoundOpen.value = false
}

///  --------  WALLET SIGN IN  ----------------
const {
  connectAsync,
  connectors,
  error: wagmiError,
} = useConnect()

const emailConnectors = computed(() => connectors.value.filter((connector) => connector.name === 'CrossMint'))
const walletConnectors = computed(() => prioritizeConnectors(connectors.value))

const { disconnectAsync } = useDisconnect()
const address = ref()

const selectedConnector = ref()

watch(wagmiError, () => {
  console.error('wagmiError', wagmiError.value)
})

const { address: cachedAddress, connector: activeConnector } = useAccount()

const isChangingConnector = ref(false)

const walletSign = async ({ connector }) => {
  selectedConnector.value = connector

  if (isAddWallet.value) {
    if (connector?.id !== activeConnector.value?.id) {
      isChangingConnector.value = true
    } else {
      updateWalletInfo({ connector, address: cachedAddress.value?.toLowerCase(), connectionType: activeConnector.value?.name })
    }
  }

  if (isLogin.value) {
    sendAuth('SIGN_IN')

    if (connector?.id == activeConnector.value?.id && cachedAddress.value) {
      address.value = cachedAddress.value

      sendAuth({ type: 'WALLET_LOGIN', data: { address: cachedAddress.value.toLowerCase(), connectionType: activeConnector.value?.name } })

      return
    } else if (activeConnector.value?.id) {
      await disconnectAsync()
    }

    await connectAsync({ connector })

    sendAuth({ type: 'WALLET_LOGIN', data: { address: cachedAddress.value?.toLowerCase(), connectionType: activeConnector.value?.name } })
  } else {
    sendAuth('SIGN_UP')

    if (connector?.id == activeConnector.value?.id && cachedAddress.value) {
      address.value = cachedAddress.value
      sendAuth({ type: 'WALLET_SIGN_UP', data: { address: cachedAddress.value.toLowerCase(), connectionType: activeConnector.value?.name } })

      return
    } else if (activeConnector.value?.id) {
      await disconnectAsync()
    }

    await connectAsync({ connector })

    sendAuth({ type: 'WALLET_SIGN_UP', data: { address: cachedAddress.value?.toLowerCase(), connectionType: activeConnector.value?.name } })
  }
}

const navigateToFAQ = (hash: string) => {
  sendConnectModal('CLOSE')

  close()

  router.push({ name: 'faq', hash: `#${hash}` })
}

// TODO: do we need to do this?
watch(address, (newAddress) => {
  if (newAddress) {
    updateWalletInfo({ connector: activeConnector.value, address: newAddress.toLowerCase(), connectionType: activeConnector.value?.name })
  }
})

watch(cachedAddress, (newAddress) => {
  address.value = newAddress
})

onUnmounted(() => {
  subscriber.unsubscribe()
})
</script>
